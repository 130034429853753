import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { useParams } from "react-router";
import { ReactComponent as CheckSvg } from "../../assets/img/Check.svg";
import { ReactComponent as InfoSvg } from "../../assets/img/info.svg";
import { useUserPage } from "../../sprUtilityHooks/sprlocationHookcontext";

import i18n from "../../i18n";
import {
  doExternalCalls,
  getMandatoryFields,
  getRetailerList,
  registerProduct,
} from "../../service/ProductService";
import { AppContext } from "../../store/AppProvider";
import { SHOW_ERROR_ALERT } from "../../store/reducer/AlertReducer";
import { canadaCardLinks, defaultDynFormParams, languageOptions, sweepStakes, usCardLinks, zvModels } from "../../utils/dataConstants";
import { RegForm, handleRegistria, handleSignIn, handleSignUp, isEmptyObj, scrollToTop } from "../../utils/utils";
import BackImage from '../../assets/img/bgImage.png';
import './Home.css';
import { prod_reCAPTCHA_SITE_KEY, zvUniverse } from "../../config";
import ReCAPTCHA from "react-google-recaptcha";
import {
  ProductInformation,
  UserInformation,
  YourProductInformation,
  Banner,
} from "../../components/components";
import { HIDE_LOADER, SHOW_LOADER } from "../../store/reducer/BLoaderReducer";
import { setSessionStorage } from "../../service/SessionStorageService";
import { ReactComponent as PromotionSvg } from "../../assets/img/promotion.svg";
import { productInfo } from "../../utils/Constants";

export const Home = () => {
  const { t } = useTranslation();
  const { locale } = useParams();
  const sectionRef = useRef(null);
  const captchaRef = useRef(null);
  const currentPage = useUserPage();

  const browserDetectedLocale = i18n?.resolvedLanguage;
  const { country, countryCode } = languageOptions[browserDetectedLocale];
  const cardLinks = country === "us" ? usCardLinks : canadaCardLinks;
  const frCss = browserDetectedLocale === "fr_CA" ? "fr-h2" : "";

  const { setShowAlert, setShowBLoader } = useContext(AppContext);

  const [showForm, setShowForm] = useState("");
  const [showLoadingButton, setShowLoadingButton] = useState(false);
  const [toggle, setToggle] = useState(false);

  const [user, setUser] = useState(null);
  const [yourProduct, setYourProduct] = useState(null);
  const [dynProductFormData, setDynProductFormData] =
    useState(defaultDynFormParams);

  const handleContinueGuest = () => {
    //capturing event for Google analytics
    console.log("currentPage", currentPage);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "form-start",
      buttonText: "Continue as Guest",
    });
    var event = new CustomEvent("componentInteraction", {
      detail: {
        page: currentPage,
        type: "form-start",
        event: "form-start",
        label: "Continue as Guest",
      },
    });

    console.log(event);
    document.dispatchEvent(event);

    setToggle(true);
    setShowForm(RegForm.UF);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 2);
  }, []);

  //handling the submit action
  const onSubmit = async (productData, file) => {
    //capturing event for adobe analytics

    const urlParams = new URLSearchParams(window.location.search.toLowerCase());
    const utm_source = urlParams.get("r");
    if (utm_source) {
      productData.utm_source = utm_source;
    }
    setShowLoadingButton(true);
    try {
      const token = await captchaRef.current.execute();
      captchaRef.current.reset();
      const data = await registerProduct({
        user,
        product: productData,
        captcha: token,
      });
      //capturing event for Google analytics
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "form_submit_step3",
        pricePaid: productData?.price_paid,
        retailername: productData?.retailer_name,
        optInEmail: productData?.receive_product_communication,
      });

      // for buildSonyDataLayer
      const productInformation = {
        sku: productData?.sku,
        pricePaid: productData?.price_paid,
        retailername: productData?.retailer_name,
        purchaseType: productData?.purchaseType,
        purchaseDate: productData?.purchase_date,
        deliveryDate: productData?.delivery_date,
        optInEmail: productData?.receive_product_communication,
      }
			setSessionStorage(productInfo, JSON.stringify(productInformation));

      // var event = new CustomEvent("componentInteraction", {
      //   detail: {
      //     page: "my products",
      //     type: "submit-registration",
      //     event: "submit-registration",
      //     label: "submit Registration",
      //   },
      // });
      // document.dispatchEvent(event);
      // console.log(event);

      //capturing event for Google analytics

      if (data?.uploadPopData) {
        const S3Url = data?.uploadPopData.split("&x-amz-security-token")[0];
        await axios({
          method: "PUT",
          url: S3Url,
          data: file,
          headers: { "Content-Type": file.type },
        });
      }

      if (Boolean(data)) {
        doExternalCalls(data.token);
        if (data?.productDetails)
          setSessionStorage(
            "registeredProduct",
            JSON.stringify(data?.productDetails)
          );
        await handleRegistria(
          data.token,
          data.registrationId,
          productData,
          browserDetectedLocale,
          false
        );
      } else {
        setShowLoadingButton(false);
        setShowAlert({
          type: SHOW_ERROR_ALERT,
          message: t("alertMessages.error"),
        });
      }
    } catch (error) {
      setShowLoadingButton(false);
      if (error?.response?.status == "521") {
        setShowAlert({
          type: SHOW_ERROR_ALERT,
          message: t("alertMessages.duplicateProduct"),
        });
      } else {
        setShowAlert({
          type: SHOW_ERROR_ALERT,
          message: error?.message || t("alertMessages.error"),
        });
      }
    }
  };

  const captureUserInfo = (user) => {
    //capturing event for Google analytics
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "form_submit_step1",
      firstName: user?.first_name,
      lastName: user?.last_name,
      emailAddress: user?.email,
      state: user?.address?.state,
      zip: user?.address?.zip,
    });
    //capturing event for adobe analytics

    var event = new CustomEvent("componentInteraction", {
      detail: {
        page: currentPage,
        type: "form-continue",
        event: "form-continue",
        label: "continue",

      },
    });
    console.log(event);
    document.dispatchEvent(event);
    // dummy call to invoke lamda
    const invokeRetailerList = async () => {
      try {
        await getRetailerList("DELL", "all");
      } catch (error) {
        console.log(error);
      }
    };
    invokeRetailerList();
    setUser(user);
    scrollToTop("blockInfo");
    setShowForm(RegForm.YF);
  };

  const fetchDynFormData = async (yourProductData) => {
    try {
      setShowBLoader({ action: SHOW_LOADER })
      const dynFormData = await getMandatoryFields(
        yourProductData?.product_id,
        countryCode,
        yourProductData?.purchase_date,
        yourProductData?.delivery_date
      );
      if (!isEmptyObj(dynFormData)) {
        setDynProductFormData(dynFormData);
      }
    } catch (error) {
      setShowAlert({
        type: SHOW_ERROR_ALERT,
        message: error?.message || t("alertMessages.error"),
      });
    } finally {
      setShowBLoader({ action: HIDE_LOADER });
    }
  };

  const captureYourProductInfo = async (yourProductData) => {
    await fetchDynFormData(yourProductData);
    //capturing event for Google analytics
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "form_submit_step2",
      SKU: yourProductData?.sku,
      purchaseDate: yourProductData?.purchase_date,
      deliveryDate: yourProductData?.delivery_date,
    });

    setYourProduct(yourProductData);
    setShowForm(RegForm.PF);
    scrollToTop("blockInfo");
  };

  const chanceToWinSweepstackes = () => {
    var event = new CustomEvent("componentInteraction", {
      detail: {
        page: currentPage,
        type: "click win-sweepstakes",
        event: "interactive click",
        label: "Chance To Win Sony Sweepstakes",
      },
    });
    console.log(event);
    document.dispatchEvent(event);
    window.open(cardLinks[0], "_blank");
  };

  return (
    <div id="register">
      <Banner />
      <ReCAPTCHA
        sitekey={prod_reCAPTCHA_SITE_KEY}
        size="invisible"
        ref={captchaRef}
      />
      <section id="main" className="about" ref={sectionRef}>
        <div className="container">
          <div className="row content form-registration">
            <div className="col-lg-12 d-flex flex-column justify-content-between">
              <div>
                <div className="row mx-0 d-flex align-items-center justify-content-between">
                  <div className="col-auto px-0">
                    <h2 className={`m-0 ${frCss}`}>
                      {t("productRegistration.Product Registration")}
                    </h2>
                  </div>
                  <div className="col-auto px-0">
                    <button
                      className="account d-block"
                      onClick={(e) => handleSignUp(e, null, locale)}
                    >
                      {t("login.Create Account")}
                    </button>
                  </div>
                </div>
                <div
                  className="row mx-0 d-flex align-items-center justify-content-between"
                  style={{ marginTop: 10 }}
                >
                  <div className="col-auto px-0">
                    <div className={`m-0 title ${frCss}`}>
                      {t("productRegistration.Already have an Account?")}
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <button
                      className="login d-block"
                      onClick={(e) => handleSignIn(e, null, locale)}
                    >
                      {t("login.Login")}
                    </button>
                  </div>
                </div>
                <div
                  className={`d-flex align-items-center justify-content-end`}
                  style={{ marginTop: 10 }}
                >
                  <button
                    className={`account ${toggle ? "d-none" : "d-block"}`}
                    onClick={() => handleContinueGuest()}
                  >
                    {t("login.Continue as a Guest")}
                  </button>
                </div>
                <div id="blockInfo" className='d-block'>
                  <p className="info mb-0">
                    <InfoSvg />
                    <span className="text-bold  ms-2">
                      {t("productRegistration.limitedWarrantyRights")}
                    </span>
                    &nbsp;
                    {t("productRegistration.fillOutInformation")}
                  </p>

                </div>
                <hr className="hr-top" id="hr-top" />
                <div
                  id="multiCollapse1"
                  className={`collapse multi-collapse ${Boolean(showForm) ? "show" : ""
                    }`}
                >
                  <div className="d-flex align-items-center step">
                    <div className="step1">
                      <span
                        className={`circule ${showForm === RegForm.UF ? "active" : ""
                          }`}
                      >
                        {showForm === RegForm.UF ? 1 : <CheckSvg />}
                      </span>
                      <p className="m-0 active">
                        {t("productRegistration.Your Information")}
                      </p>
                    </div>
                    <div className="Rectangle"></div>
                    <div className="step1">
                      <span
                        className={`circule ${showForm === RegForm.YF ? "active" : ""
                          }`}
                      >
                        {showForm === RegForm.YF || !Boolean(yourProduct) ? (
                          2
                        ) : (
                          <CheckSvg />
                        )}
                      </span>
                      <p className="m-0">
                        {t("productRegistration.Your Product")}
                      </p>
                    </div>
                    <div className="Rectangle"></div>
                    <div className="step1">
                      <span
                        className={`circule ${showForm === RegForm.PF && "active"
                          }`}
                      >
                        3
                      </span>
                      <p className="m-0">
                        {t("productRegistration.Product Information")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="customPr">
                  <UserInformation
                    show={showForm === RegForm.UF}
                    onButtonPress={(user) => captureUserInfo(user)}
                  />
                  <YourProductInformation
                    show={showForm === RegForm.YF}
                    userData={user}
                    onBackButtonPress={() => {
                      setShowForm(RegForm.UF);
                      scrollToTop("blockInfo");
                    }}
                    onButtonPress={(yourProductData) =>
                      captureYourProductInfo(yourProductData)
                    }
                  />
                  <ProductInformation
                    show={showForm === RegForm.PF}
                    yourProduct={yourProduct}
                    showLoadingButton={showLoadingButton}
                    dynProductFormData={dynProductFormData}
                    onBackButtonPress={() => {
                      setShowForm(RegForm.YF);
                      scrollToTop("blockInfo");
                    }}
                    onButtonPress={(productData, file) =>
                      onSubmit(productData, file)
                    }
                  />
                </div>
                <img
                  id="bgImage"
                  width="600"
                  height="400"
                  alt="bgImage"
                  src={BackImage}
                  className={toggle ? "d-none" : ""}
                  onClick={() => handleContinueGuest()}
                />
              </div>
            </div>
            <div className="pt-5 d-flex justify-content-around">
              <div
                className="bg-block align-self-end me-md-0 me-2 tilesContainer"
                onClick={() => chanceToWinSweepstackes()}
              >
                <img
                  src={sweepStakes[browserDetectedLocale]["news"]}
                  alt="Chance to Win Sony Sweepstakes"
                />
              </div>
              <div
                className="bg-block align-self-end ms-md-0 ms-2 tilesContainer"
                onClick={() => window.open(cardLinks[1], "_blank")}
              >
                <img
                  src={sweepStakes[browserDetectedLocale]["shop"]}
                  alt="Shop Sony Products"
                />
              </div>
            </div>
            {country === "us" && browserDetectedLocale === "en_US" && (
              <>
                <div id="purchase" className="d-block">
                  <span className="text-bold">
                    <a
                      href="https://electronics.sony.com/financialnotice"
                      target="_blank"
                    >
                      {t("productRegistration.Financial Incentives")}
                    </a>
                  </span>
                  <p className="info">
                    <span className="text-bold">
                      {t("productRegistration.purchase")}
                    </span>
                    <br />
                    <span className="text-bold">
                      {t("productRegistration.purchaseOffer")}
                    </span>
                    &nbsp;
                    <a
                      href="https://productregistration.sony.com/"
                      target="_blank"
                    >
                      {"https://productregistration.sony.com/."}
                    </a>
                    &nbsp;
                    <span className="text-bold">
                      {t("productRegistration.4monthFreeTrial")}
                    </span>
                    &nbsp;(
                    <a href="https://music.amazon.com/settings" target="_blank">
                      {"https://music.amazon.com/settings"}
                    </a>
                    ).&nbsp;
                    <span className="text-bold">
                      {t("productRegistration.termsAndCondition")}
                    </span>
                    <br />
                    <span className="text-bold">
                      {t("productRegistration.amazonReserve")}
                    </span>
                  </p>
                </div>
                <div id="noPurchase" className="d-block">
                  <p className="info">
                    <span className="text-bold">
                      {t("productRegistration.noPurchase")}
                    </span>
                    &nbsp;
                    <a
                      href="https://electronics.sony.com/registration-sweepstakes"
                      target="_blank"
                    >
                      {t("productRegistration.here")}
                    </a>
                    <span className="text-bold">
                      {t("productRegistration.voidTerritories")}
                    </span>
                  </p>
                </div>
                {/* zv series temp change */}
                {country === 'us' &&
                  <div id="zv-series" className="d-block">
                    <p className="info">
                      <PromotionSvg />&nbsp;
                      <span className="text-bold">
                        {'Between 10/01/24 and 3/31/25,buy an Eligible Product'}&nbsp;
                        (<span className="zv-tooltip zv-list">see list
                          <span className="tooltiptext">
                            {zvModels?.map((ele, index) => {
                              if (index % 4 === 0) {
                                return <span key={index}>{ele}<br /></span>
                              } else {
                                return <span key={index}>{ele},&nbsp;</span>
                              }
                            })}
                          </span>
                        </span>)
                        &nbsp;
                        {'and get a free Peak Design Camera Cuff (color to be determined based on availability) and 1 Additional Year on the Limited Warranty accompanying the product, subject to the Terms and Conditions. Redeem no later than April 15, 2025 at'}
                      </span>
                      <a
                        href={zvUniverse}
                        target="_blank"
                      >
                        &nbsp;{zvUniverse}&nbsp;
                      </a>
                      <span className="text-bold">
                        {'by completing the product registration and upload: (i) A copy of the purchase receipt for the Eligible product that includes the Sony Authorized Retailer’s name and address. The purchase date and purchased model(s); and (ii) serial number of Eligible Product. See complete Terms and Conditions for details.'}
                      </span>
                    </p>
                  </div>}
              </>)
            }
          </div>
        </div>
      </section>
    </div>
  );
};
