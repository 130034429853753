exports.refreshToken = 'refreshToken';
exports.accessToken = 'accessToken';
exports.loggedInUser = 'loggedInUser';
exports.userInfo = 'userInfo';
exports.productInfo = 'productInfo';
exports.stateCode = 'stateCode';
exports.scopeValue = 'openid users';
exports.user = 'user';
exports.authorize = 'authorize';
exports.oauth = 'oauth2/token';
exports.xhrCall ='PORTAL.XHR';
exports.showConfirmationPage="showConfirmationPage"