import React, { useContext, useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getAddress } from "../../service/ProductService";
import { AppContext } from "../../store/AppProvider";
import { SHOW_ERROR_ALERT } from "../../store/reducer/AlertReducer";
import Input, { parsePhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input/input'
import i18n from "../../i18n";
import { canadaStateList, languageOptions } from "../../utils/dataConstants";
import 'react-phone-number-input/style.css';
import { HIDE_LOADER, SHOW_LOADER } from "../../store/reducer/BLoaderReducer";
import { Tooltip } from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';

import './UserInformation.css'

export const UserInformation = ({ onButtonPress, show = true }) => {
  const {
    setShowAlert,
    setShowBLoader
  } = useContext(AppContext);

  const locale = i18n.resolvedLanguage;
  const { country } = languageOptions[locale];
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    control,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldUseNativeValidation: true,
    defaultValues: { firstName: '', lastName: '', phone: '', email: '', state: '', postalCode: '', purchaseType: "online" },
  });

  const [phoneIn, setPhoneIn] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [stateCode, setStateCode] = useState('');
  const [validPostalCode, setValidPostalCode] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [showEmailTooltip, setShowEmailTooltip] = useState(false);

  const validationRules = {
    firstName: {
      required: t("validation.firstNameReq"),
      maxLength: {
        value: 20,
        message: t("validation.maxNameLen"),
      },
      pattern: {
        value: /^[a-zA-Z-'. ]+$/,
        message: t("validation.invalidNameFmt"),
      },
    },
    lastName: {
      required: t("validation.lastNameReq"),
      maxLength: {
        value: 20,
        message: t("validation.maxNameLen"),
      },
      pattern: {
        value: /^[a-zA-Z-'. ]+$/,
        message: t("validation.invalidNameFmt"),
      },
    },
    postalCode: {
      required: t("validation.postalCodeReq"),
      validate: {
        required: (v) => {
          if (!validPostalCode) return t("validation.validPostalCode");
        }
      },
    },
    email: {
      required: t("validation.emailReq"),
      pattern: {
        value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        message: t("validation.validEmail"),
      },
      maxLength: {
        value: 50,
        message: t("validation.maxEmailLength")
      }
    },
    phone: {
      message: t("validation.InvalidPhone"),
      maxLength: {
        value: 15,
        message: t("validation.maxNameLen"),
      },
    },
  };

  const searchPostalCode = useWatch({
    control,
    name: 'postalCode'
  });

  //postal code information fetcher
  useEffect(() => {
    const fetchAddress = async () => {
      try {
        setShowBLoader({ action: SHOW_LOADER })
        const data = await getAddress(searchPostalCode);
        setStateList([data])
        setValue("city", data?.places[0]["place name"]);
        setValue("state", data?.places[0]["state"]);
        setStateCode(data?.places[0]["state abbreviation"]);
        setValidPostalCode(true);
      } catch (error) {
        setStateList([])
        setValidPostalCode(false);
        setShowAlert({
          type: SHOW_ERROR_ALERT,
          message:
            t("alertMessages.invalidPostalCode") + " " + searchPostalCode,
        });
      } finally {
        setShowBLoader({ action: HIDE_LOADER })
      }
    };

    if (country === "us") {
      if (searchPostalCode?.length !== 5) {
        setValue("city", "");
        setValue("state", "");
        setStateList([])
        setValidPostalCode(false);
      } else {
        fetchAddress();
      }
    }
    if (country === 'ca') {
      if (searchPostalCode && searchPostalCode?.length !== 6) {
        setValidPostalCode(false);
      } else {
        setValidPostalCode(true);
        setStateList(canadaStateList);
      }
    }
  }, [searchPostalCode, setValue]);

  const checkSpecialChar = (e) => {
    if (!/[0-9a-zA-Z]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const onError = (errors, event) => {
    var form = document.getElementById("multiCollapseExample1");
    if (!!phoneIn && phoneIn != "+1") {
      !isPossiblePhoneNumber(`${phoneIn}`) && setPhoneError(true);
    }
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add("was-validated");
  };

  const onSubmit = (formData, event) => {
    event?.preventDefault();
    if (!!phoneIn && phoneIn != "+1") {
      let isValid = isPossiblePhoneNumber(`${phoneIn}`);
      if (!isValid) {
        setPhoneError(true);
        return;
      } else { setPhoneError(false) };
    } else {
      setPhoneError(false)
    }
    try {
      const user = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone: phoneIn ? parsePhoneNumber(phoneIn).number.slice(2) : "",
        phone_country_code: phoneIn ? `+${parsePhoneNumber(phoneIn).countryCallingCode}` : "",
        address: {
          street_address: formData.address,
          city: formData.city || "",
          state: country === "us" ? stateCode || '' : (!!formData.state ? JSON.parse(formData?.state)["state abbreviation"] : ''),
          zip: formData.postalCode,
          country,
          locale,
        },
      };
      onButtonPress(user);
    } catch (error) {
      setShowAlert({ type: SHOW_ERROR_ALERT, message: t('alertMessages.error') });
    }
  };

  let { firstName, lastName, email, postalCode } = validationRules;

  const postalUsPattern = {
    pattern: {
      value: /^[0-9]+$/,
      message: t("validation.postalCodeMustBeDigit"),
    }
  }

  if (country === "us") {
    postalCode = { ...postalCode, ...postalUsPattern }
  }

  const { errors } = formState;
  const errorStyle = {
    display: 'block',
    background: "rgb(220, 53, 69)",
    padding: "10px 15px",
    borderRadius: "6px",
    color: "#fff"
  }

  const emailTooltipTitle = t("productRegistration.emailTooltip");


  return (
    <form
      className={`collapse multi-collapse ${show ? "show" : ""} needs-validation`}
      id="multiCollapseExample1"
      onSubmit={handleSubmit(onSubmit, onError)}
      noValidate
      autoComplete="off"
    >
      <div className="d-grid justify-content-between flex-wrap margin-label form-group-wrapper">
        <div className="form-group px-0 d-flex flex-column-reverse justify-content-end">
          <input
            name="firstName"
            type="text"
            className="form-control order-2"
            id="exampleInputName"
            required
            {...register("firstName", firstName)}
          />
          <label
            htmlFor="exampleInputName"
            className="invalid-feedback form-label order-2"
          >
            {t("productRegistration.First Name")} *
          </label>
          <div className="invalid-feedback order-1">
            {errors.firstName ? errors.firstName?.message : t('validation.firstNameReq')}
          </div>
        </div>

        <div className="last__name form-group px-0 d-flex flex-column-reverse justify-content-end">
          <input
            name="lastName"
            type="text"
            className="form-control order-2"
            id="exampleInputLastName"
            required
            {...register("lastName", lastName)}
          />
          <label
            htmlFor="exampleInputLastName"
            className="invalid-feedback form-label order-2"
          >
            {t("productRegistration.Last Name")} *
          </label>
          <div className="invalid-feedback order-1 w-100">
            {errors.lastName ? errors.lastName?.message : t('validation.lastNameReq')}
          </div>
        </div>
        <div className="form-group px-0 d-flex flex-column-reverse justify-content-end">
          <input
            name="email"
            type="email"
            className="form-control order-2"
            id="exampleInputEmail1"
            required
            {...register("email", email)}
          />
        {country === 'us' ?
        <ClickAwayListener onClickAway={()=> setShowEmailTooltip(false)}>
          <label
            htmlFor="exampleInputEmail1"
            className="invalid-feedback form-label order-2"
          >
            <Tooltip
            title={emailTooltipTitle}
            placement="top-start"
            disableFocusListener
            disableHoverListener
            disableInteractive
            open={showEmailTooltip}
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
            >
              <span onClick={(e) => {e.preventDefault(); setShowEmailTooltip(true) }} style={{textDecoration: 'underline', cursor: 'pointer'}}>
              {t("productRegistration.Email")}
              </span>
            </Tooltip> *
          </label>
        </ClickAwayListener> 
        :
          <label htmlFor="exampleInputEmail1"
          className="invalid-feedback form-label order-2">
            {t("productRegistration.Email")} *
          </label>
          }
          <div className="invalid-feedback order-1">
            {errors.email ? errors.email?.message : t('validation.emailReq')}
          </div>
        </div>
        <div className="form-group px-0 d-none d-md-flex flex-column-reverse justify-content-end"></div>

        <div className="form-group px-0 d-flex flex-column-reverse justify-content-end">
          <Input
            autoComplete="off"
            name="phone"
            country="US"
            minLength={7}
            international
            withCountryCallingCode
            maxLength={15}
            value={phoneIn}
            onChange={setPhoneIn}
            className={`form-control order-2 ${phoneError && 'error-input'}`}
            id="exampleInputPhone" />
          <label
            htmlFor="exampleInputPhone"
            className="invalid-feedback form-label order-2"
            style={{ color: phoneError && '#dc3545' }}
          >
            {t("productRegistration.Phone Number(optional)")}
          </label>
          <div className='invalid-feedback order-1' style={phoneError ? errorStyle : null}>
            {t('validation.validPhone')}
          </div>
        </div>
        <div className="form-group px-0 d-none d-md-flex flex-column-reverse justify-content-end"></div>
        <div className="form-group px-0 d-flex flex-column-reverse justify-content-end">
          <input
            name="address"
            type="text"
            className="form-control order-2"
            id="exampleInputAddress"
            {...register("address")}
          />
          <label
            htmlFor="exampleInputAddress"
            className="invalid-feedback form-label order-2"
          >
            {t("productRegistration.Street Address (optional)")}
          </label>
          <div className="invalid-feedback order-1">
            {t("validation.reqField")}
          </div>
        </div>

        <div className="last__name form-group px-0 d-flex flex-column-reverse justify-content-end">
          <input
            name="city"
            type="text"
            className="form-control order-2"
            id="exampleInputLastCity"
            {...register("city")}
          />
          <label
            htmlFor="exampleInputLastCity"
            className="invalid-feedback form-label order-2"
          >
            {t("productRegistration.City (optional)")}
          </label>
          <div className="invalid-feedback order-1">
            {t("validation.reqField")}
          </div>
        </div>

        <div className="form-group px-0 d-flex flex-column-reverse justify-content-end">
          <select
            className="form-select order-2"
            id="exampleInputState"
            name="state"
            defaultValue={""}
            disabled={country === 'us'}
            style={{ backgroundImage: country === 'us' && "url('')" }}
            {...register("state")}
          >
            {country === "ca" && <option value=""></option>}
            {stateList?.map(({ places }) => (
              <option
                value={JSON.stringify(places[0])}
                key={places[0]?.state}
              >
                {places[0]?.state}
              </option>
            ))}
          </select>
          <label
            htmlFor="exampleInputState"
            className="select invalid-feedback form-label order-2"
          >
            {t("productRegistration.State (optional)")}
          </label>
          <div className="invalid-feedback order-1">
            {t('validation.street')}
          </div>
        </div>
        <div className="last__name form-group px-0 d-flex flex-column-reverse justify-content-end">
          <input
            name="postalCode"
            type="text"
            maxLength={country === "us" ? "5" : "6"}
            className="form-control order-2"
            id="exampleInputLastCode"
            required
            onKeyDown={(e) => checkSpecialChar(e)}
            {...register("postalCode", postalCode)}
          />
          <label
            htmlFor="exampleInputLastCode"
            className="invalid-feedback form-label order-2"
          >
            {t("productRegistration.Postal Code")}&nbsp;*
          </label>
          <div className="invalid-feedback order-1">
            {errors.postalCode ? errors.postalCode?.message : t('validation.postalCodeReq')}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end block-continue">
        <button
          id="stepOne"
          type="submit"
          className="login"
          aria-expanded="false"
          aria-controls="multiCollapseExample1 multiCollapseExample2 multiCollapseExample3 multiCollapse1 multiCollapse2 multiCollapse3"
        >
          {t("productRegistration.Continue")}
        </button>
      </div>
    </form>
  );
};